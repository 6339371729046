import React from 'react'
import Logo from '../assets/svg/rkc-logo.svg'
import Office from './office'

const Header = ({ new_york, la, email, ig }) => {
  return (
    <header className="header">
      <div className="header__wrapper">
        <div className="header__item header__logo">
          <Logo />
        </div>
        <div className="header__item header__office">
          <Office
            city="New York"
            address={new_york[0].address.html}
            number={new_york[0].phone_number.text}
          />
        </div>
        <div className="header__item header__office">
          <Office
            city="Los Angeles"
            address={la[0].address.html}
            number={la[0].phone_number.text}
          />
        </div>
        <div className="header__item header__contact">
          <a href={`tel:${new_york[0].phone_number.text}`}>
            NY {new_york[0].phone_number.text}
          </a>
          <a href={`tel:${la[0].phone_number.text}`}>
            LA {la[0].phone_number.text}
          </a>
          <a href={`mailto:${email.text}`}>{email.text}</a>
        </div>
        <div className="mobile__contact">
          <a href={`mailto:${email.text}`}>{email.text}</a>
          <a target="_blank" rel="noopener noreferrer" href={ig.text}>
            Instagram
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header
