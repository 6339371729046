import { useState, useEffect, useRef } from 'react'

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useSupportsPassive() {
  const [supportsPassive, setSupportsPassive] = useState(false)

  useEffect(() => {
    try {
      var opts = Object.defineProperty({}, 'passive', {
        get: function() {
          return setSupportsPassive(true)
        }
      })
      window.addEventListener("testPassive", null, opts)
      window.removeEventListener("testPassive", null, opts)
    } catch (e) {}
  }, [])

  return supportsPassive
    ? { passive: true }
    : false
}

// https://usehooks.com/useEventListener/
export function useEventListener(eventName, handler, options) {
  // Create a ref that stores handler
  const savedHandler = useRef()

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  const supportsPassive = useSupportsPassive()

  useEffect(
    () => {
      // Id element
      const element = options.element || global

      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener
      if (!isSupported) return

      // Create event listener that calls handler function stored in ref
      const eventListener = event => savedHandler.current(event)

      // Does it want to be passive?
      // If so, reference supportsPassive to see if it can be
      const passive = options.passive
        ? supportsPassive
        : false

      // Add event listener
      element.addEventListener(eventName, eventListener, passive)

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener, passive)
      }
    },
    [eventName, options] // Re-run if eventName or element changes
  )
}
