import React from 'react'

const Office = ({ city, address, number }) => {
  return (
    <div className="office">
      <div className="office__inner">
        <p className="office__city">{city} Office</p>
        <div
          className="office__address"
          dangerouslySetInnerHTML={{ __html: address }}
        ></div>
        <a href={`tel:${number}`} className="office__number">
          TEL {number}
        </a>
      </div>
    </div>
  )
}

export default Office
