import { createMedia } from '@artsy/fresnel'

const ExampleAppMedia = createMedia({
  breakpoints: {
    xs: 0,
    sm: 750,
    md: 990,
    lg: 1400,
  },
})

// Make styles for injection into the header of the page
export const mediaStyles = ExampleAppMedia.createMediaStyle()

export const { Media, MediaContextProvider } = ExampleAppMedia
