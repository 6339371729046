import React, { useState, useEffect } from 'react'
import { useEventListener } from '../hooks'
import 'proxy-polyfill'
import { window, document, exists } from 'browser-monads'
import { Media } from '../media'
import * as basicScroll from 'basicscroll'
import Office from './office'

const Footer = ({ basicScrollInstance, setBasicScrollInstance, new_york, la, email, ig, layout, desktop }) => {
  const footerRef = React.createRef()

  useEffect(() => {
    if(!desktop && basicScrollInstance) {
      basicScrollInstance.destroy()
      setBasicScrollInstance(undefined)
    }

    if(desktop && !basicScrollInstance) {
      const footerHeight = footerRef.current.offsetHeight,
            instance = basicScroll.create({
              elem: footerRef.current,
              from: `top-bottom`,
              to: `bottom-bottom`,
              track: true,
              props: {
                "--translateY": {
                  from: "0px",
                  to: `${footerHeight * -1}px`,
                },
              },
            })

      instance.start()
      window.dispatchEvent(new Event("resize"))

      setBasicScrollInstance(instance)
    }
  }, [layout, desktop])

  return (
    <footer className="header header--footer" ref={footerRef}>
      <Media lessThan="sm">
        {(mediaClassNames) => {
          return (
            <div className={`header__wrapper ${mediaClassNames}`}>
              <div className="header__item header__office">
                <Office
                  city="New York"
                  address={new_york[0].address.html}
                  number={new_york[0].phone_number.text}
                />
              </div>
              <div className="header__item header__office">
                <Office
                  city="Los Angeles"
                  address={la[0].address.html}
                  number={la[0].phone_number.text}
                />
              </div>
              <div className="mobile__contact">
                <a href={`mailto:${email.text}`}>{email.text}</a>
                <a>© {new Date().getFullYear()} RK communications</a>
                <a target="_blank" rel="noopener noreferrer" href={ig.text}>
                  Instagram
                </a>
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://studio.sl/"
                >
                  Website by Spevack Loeb
                </a>
              </div>
            </div>
          )
        }}
      </Media>
      <Media greaterThanOrEqual="sm">
        {(mediaClassNames) => {
          return (
            <div className={`header__wrapper ${mediaClassNames}`}>
              <div className="header__item footer__meta">
                <a target="_blank" rel="noopener noreferrer" href={ig.text}>
                  Instagram
                </a>
                <p>© {new Date().getFullYear()} RK communications</p>
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://studio.sl/"
                >
                  Website by Spevack Loeb
                </a>
              </div>
              <div className="header__item header__office">
                <Office
                  city="New York"
                  address={new_york[0].address.html}
                  number={new_york[0].phone_number.text}
                />
              </div>
              <div className="header__item header__office">
                <Office
                  city="Los Angeles"
                  address={la[0].address.html}
                  number={la[0].phone_number.text}
                />
              </div>
              <div className="header__item header__contact">
                <a href={`tel:${new_york[0].phone_number.text}`}>
                  NY {new_york[0].phone_number.text}
                </a>
                <a href={`tel:${la[0].phone_number.text}`}>
                  LA {la[0].phone_number.text}
                </a>
                <a href={`mailto:${email.text}`}>{email.text}</a>
              </div>
            </div>
          )
        }}
      </Media>
    </footer>
  )
}

export default Footer
