/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useRef, useState } from 'react'
import { useEventListener, usePrevious } from '../hooks'
import { debounce } from 'throttle-debounce'
import { useStaticQuery, graphql } from 'gatsby'
import 'proxy-polyfill'
import { window, document, exists } from 'browser-monads'
import Header from './header'
import Footer from './footer'

const OPTIONS_QUERY = graphql`
  query OptionsQuery {
    prismicThemeOptions {
      data {
        contact_email {
          text
        }
        instagram_link {
          text
        }
        la_office {
          address {
            html
          }
          phone_number {
            text
          }
        }
        new_york_office {
          address {
            html
          }
          phone_number {
            text
          }
        }
      }
    }
  }
`

const Layout = ({ children }) => {
  const {
    prismicThemeOptions: {
      data: { contact_email, instagram_link, la_office, new_york_office },
    },
  } = useStaticQuery(OPTIONS_QUERY)

  // Keep mobile state
  const clientWidth = useRef(document.documentElement.clientWidth),
        [mobile, setMobile] = useState(document.documentElement.clientWidth <= 1000),
        [layoutChange, setLayoutChange] = useState(false)

  const [basicScrollInstance, setBasicScrollInstance] = useState(null)

  // Watch for changes to layout
  useEventListener('resize', debounce(250, (e) => {
    const newClientWidth = document.documentElement.clientWidth

    // Transitioned to desktop
    if(clientWidth.current <= 989 && newClientWidth > 989) {
      setMobile(false)
      setLayoutChange(true)
    // Transitioned to mobile
    } else if (clientWidth.current > 989 && newClientWidth <= 989) {
      setMobile(true)
      setLayoutChange(true)
    // No change
    } else {
      setLayoutChange(false)
    }

    clientWidth.current = newClientWidth
  }), { passive: true })

  useEventListener('click', (e) => {
    console.log(e.target)
  }, { passive: true })

  return (
    <>
      <Header
        new_york={new_york_office}
        la={la_office}
        email={contact_email}
        ig={instagram_link}
      />
      <main>
        {children}
      </main>
      <Footer
        basicScrollInstance={basicScrollInstance}
        setBasicScrollInstance={setBasicScrollInstance}
        new_york={new_york_office}
        la={la_office}
        email={contact_email}
        ig={instagram_link}
        layout={layoutChange}
        desktop={!mobile}
      />
    </>
  )
}

export default Layout
